import { Loader, Dimmer } from "semantic-ui-react";
import React from "react";
import { GAService } from "@services/GAService";
import { navigate, graphql } from "gatsby";
import { OrderConfirmationService } from "@services/orderConfirmationService";
import { OrderConfirmationDataModel } from "@models/OrderConfirmation";
import { userFacebookPixelSent } from "@redux/localStorage/user";
import { PaymentService } from "@services/PaymentService";
import { encodeURIParameterIfNotEncoded } from "@utils/Helpers";
import { mapOrderConfirmationToGAEcommerceEventModel, mapPurchaseModelToGAEcommerceEventModel } from "@mappers/GoogleAnalytics/GoogleAnalytics.mapper";
import { GA4Event } from "@utils/Constant";

const queryString = require('query-string');

interface PaymentProcessProps {
    data: any
    location: any,
    flags?: any
}
const sleep = async (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms))
} 
class PaymentProcessing extends React.Component<PaymentProcessProps>{

    constructor(props: PaymentProcessProps) {
        super(props);
        this.state = {
            success: false,
            url: ''
        }
    }

    componentDidUpdate() {
        navigate(this.state.url, { replace: true })
    }

    componentDidMount() {
        const qs = queryString.parse(this.props.location.search, { parseBooleans: true, parseNumbers: true });
        if (qs) {
            const success = qs.success == 1 ? true : false;
            const receiptNumber = qs.rcpt ? qs.rcpt : undefined;
            const id = qs.key ? qs.key.replaceAll(' ', '+') : undefined;
            const method = qs.method ? qs.method : undefined;

            if (success) {
                if (method == "afterpay") {
                    console.log('afterpay auth completed')
                    const afterpayToken = qs.orderToken;
                    const status = qs.status;
                    if (status == "SUCCESS") {
                        PaymentService.authAfterpayPayment(this.props.data.site.siteMetadata.apiUrl, afterpayToken).then(
                            data => {
                                if (data.StatusCode === 202) {
                                    if (data.Status === "APPROVED") {
                                        //   this.setState({ transactionId: data.TransactionId });
                                        //get transaction details from backend and build the GA data
                                        OrderConfirmationService.getOrderConfirmationData(this.props.data.site.siteMetadata.apiUrl, encodeURIParameterIfNotEncoded(id)).then((data: any) => {
                                            if (data.data.Success) {
                                                if (data.data.Data !== null) {

                                                    const orderConfirmationData = data.data.Data as OrderConfirmationDataModel;

                                                    if (orderConfirmationData.WebPaymentSuccess) {

                                                        GAService.pushGAPurchase(orderConfirmationData);
                                                        const mappedData = mapOrderConfirmationToGAEcommerceEventModel(GA4Event.Purchase, false, orderConfirmationData)
                                                        GAService.trackEventGA4(mappedData)
                                                    }
                                                }
                                                this.setState({
                                                    success: true,
                                                    url: "/order-confirmation/?success=1&rcpt=" + receiptNumber + "&key=" + id
                                                })
                                                navigate("/order-confirmation/?success=1&rcpt=" + receiptNumber + "&key=" + id, { replace: true })
                                            }
                                        })
                                    }
                                } else {
                                    //handle payment auth failed from afterpay
                                    //redirect customer to retry payment page
                                    navigate("/review-order/?success=0&allowretry=1&msg=afterpay&paymentmethod=AfterPay&rcpt=" + receiptNumber + "&key=" + id, { replace: true });
                                }
                            }
                        ).catch(err => {
                            console.log(err);
                            this.setState({
                                success: false,
                                url: "/payment-failure/?err=unknown Payment error"
                            })
                            navigate("/payment-failure/?err=unknown Payment error")
                        }).finally(() => {
                        })
                    }

                } else {
                    const event = qs.event ? qs.event : undefined;
                    
                    const eventStr = decodeURIComponent(event);
                    const eventJson = JSON.parse(eventStr);
                    
                    if(eventJson != null || eventJson != undefined){
                        OrderConfirmationService.getOrderConfirmationData(this.props.data.site.siteMetadata.apiUrl, encodeURIParameterIfNotEncoded(id)).then((data: any) => {
                            if (data.data.Success) {
                                if (data.data.Data !== null) {
                                    const orderConfirmationData = data.data.Data as OrderConfirmationDataModel;
                                    GAService.pushGAPurchase(orderConfirmationData);
                                    const mappedData = mapOrderConfirmationToGAEcommerceEventModel(GA4Event.Purchase, false, orderConfirmationData)
                                    GAService.trackEventGA4(mappedData)

                                }
                                this.setState({
                                    success: true,
                                    url: "/order-confirmation/?success=1&rcpt=" + receiptNumber + "&key=" + id
                                })
                                navigate("/order-confirmation/?success=1&rcpt=" + receiptNumber + "&key=" + id, { replace: true })
                            }
                        })
                    } else {
                        this.setState({
                            success: true,
                            url: "/order-confirmation?success=1&rcpt=" + receiptNumber + "&key=" + id
                        })
                        navigate("/order-confirmation?success=1&rcpt=" + receiptNumber + "&key=" + id, { replace: true })
                    }
                }
            }

        }
    }

    render() {
        return (
            <Dimmer active>
                <Loader content="Processing..." />
            </Dimmer>
        );
    }
}

export default PaymentProcessing

export const query = graphql`{
    site {
      siteMetadata {
        apiUrl
        environment
        lastBuilt
      }
    }
}
`